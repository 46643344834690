import styled from "styled-components"

export const VideoSectionWrapper = styled.div`
  .content {
    color: #282828;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    #spacer {
      width: 88px;
      height: 4px;
      background: #136ef6;
      margin: 24px auto 32px auto;
    }
    h1 {
      text-align: center;
      color: #136ef6;
      text-transform: uppercase;
      font-size: 60px;
      line-height: 80px;
      font-weight: 66px;
      @media (max-width: 576px) {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }
  p {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin: 0 auto;
    max-width: 600px;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  .blog-image-wrapper {
    max-width: 1200px;
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      object-fit: cover;
    }
    :after {
      content: "";
      border-radius: 8px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0.5) 49.48%,
          rgba(0, 0, 0, 0.5) 100%
        ),
        url(.jpg);
    }
    .emptyImg {
      width: 100%;
      max-height: 800px;
      min-height: 450px;
      background: #d4d9e2;
      @media (max-width: 992px) {
        width: 100%;
        min-width: auto;
        min-height: auto;
      }
    }
  }
`

export const ThumbnailWrapper = styled.div`
  .playicon{
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    @media (max-width: 992px) {
      top: 55%;
    }
    span{
    display: inline-block;
    cursor: pointer;
    line-height: 72px;
    img{
      width:150px;
      height:150px;
      }
    }
`
