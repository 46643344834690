import styled, { css } from "styled-components"

export const Subheading = styled.div`
  color: #475467;
`

export const SwitchbackContainer = styled.div`
  font-family: Roboto, sans-serif;
  display: flex;
  margin: 96px auto;
  max-width: 1170px;
  align-items: center;
  column-gap: 20px;
  ${({ noTopPadding, noBottomPadding }) => css`
    margin-top: ${noTopPadding === true && 0};
    margin-bottom: ${noBottomPadding === true && 0};
  `}
  ${({ alignImage }) =>
    `
  flex-direction: ${alignImage === "left" ? "row-reverse" : "row"};
  `}
  .text-content-side {
    width: 48%;
    text-align: left;
  }
  .img-side {
    width: 42%;
    height: 100%;
    max-height: 470px;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    img {
      border-radius: 8px;
      max-width: 100%;
      height: auto;
      max-height: 470px;
    }
  }

  .video-side {
    width: 52%;
    display: flex;
    align-items: center;
    img {
      max-width: 100%;
      border-radius: 8px;
    }
  }

  @media (max-width: 992px) {
    align-items: stretch;
    max-width: 770px;
    .text-content-side {
      width: 100%;
      padding: 0px;
      max-width: 470px;
    }
    .img-side {
      display: flex;
      margin: auto;
      img {
        width: 100%;
        display: block;
        margin: auto;
        max-width: 600px;
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 506px;
    .img-side {
      width: 100%;
    }
  }
`
