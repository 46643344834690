import React from "react"
import { switchbackResolveRichText } from "@utils/resolve-rich-text/switchbackResolveRichText"
import { Heading, TextMono, Button } from "@atoms"
import {
  SwitchbackContainer,
  Subheading,
} from "./styles/SwitchbackStandard.styled"
import { VideoSectionSwitchback } from "../VideoSection/VideoSectionSwitchback"
import OptimizedImage from "../../utils/optimizedImage"
import { LottieWrapper } from "../Lottie/LottieWrapper"

const SwitchbackStandard = ({ component }) => {
  const {
    // id,
    heading,
    headingSize,
    subheading,
    subheadingBullet,
    kicker,
    featuredImage,
    alignImage,
    callsToAction,
    videoUrl,
    noTopPadding,
    noBottomPadding,
  } = component
  
  const video = { videoUrl: videoUrl }

  let lottie = featuredImage?.file?.contentType?.includes("json")
  const isGif = featuredImage?.file?.url.includes(".gif")

  return (
    <div className="p-six-four-two">
      <SwitchbackContainer
        alignImage={alignImage}
        noTopPadding={noTopPadding}
        noBottomPadding={noBottomPadding}
      >
        <div className="text-content-side">
          <div className="text-content-wrapper">
            {kicker && (
              <TextMono
                style={{
                  color: "#475467",
                  marginBottom: "16px",
                }}
              >
                {kicker}
              </TextMono>
            )}
            {heading && (
              <Heading
                style={{ marginBottom: "16px" }}
                headingSize={headingSize}
              >
                {heading}
              </Heading>
            )}
            {subheading && (
              <Subheading>
                {switchbackResolveRichText(subheading, subheadingBullet)}
              </Subheading>
            )}
            {callsToAction &&
              callsToAction.map((cta, idx) => (
                <Button
                  key={idx}
                  style={{ marginTop: "30px" }}
                  ctaVariant={cta?.variant || "primary"}
                  ctaLink={cta?.link}
                  ctaType={cta?.type}
                >
                  {cta?.label}
                </Button>
              ))}
          </div>
        </div>
        {featuredImage && !videoUrl && (
          <div className="img-side">
            {!lottie && (
              <OptimizedImage
                image={
                  featuredImage?.gatsbyImageData && !isGif
                    ? featuredImage.gatsbyImageData
                    : undefined
                }
                alt={featuredImage?.title || featuredImage?.description || "Switchback Image"}
                src={featuredImage?.file?.url}
              />
            )}
            {lottie && (
              <LottieWrapper imageAnimation={featuredImage?.file?.url} />
            )}
          </div>
        )}
        {videoUrl && (
          <div className="video-side">
            <VideoSectionSwitchback video={video} thumbnail={featuredImage} />
          </div>
        )}
      </SwitchbackContainer>
    </div>
  )
}

SwitchbackStandard.displayName = "SwitchbackStandard"

export default SwitchbackStandard
